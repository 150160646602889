import { createNamespacedHelpers } from 'vuex';
const commonStore = createNamespacedHelpers('Common');

export default {
    data(){

    },
    computed:{
        ...commonStore.mapGetters(['languages']),
        dd_languages(){
            const values = [];
            Object.keys(this.languages).forEach(k => {
                values.push({
                    label: this.languages[k].label,
                    id: k
                });
            })
            return values;
        }
    },
    methods: {
        LanguageLabel(lang){
            let name = '-';
            this.dd_languages.forEach(s => {
                if (s.id === lang) {
                    name = s.label;
                }
            });
            return name;
        }
    }
}
