<template>
    <div class='flex flex-column sm:flex-row justify-content-between'>
        <div>
            <Button type='button' icon='pi pi-filter-slash' label='Azzera filtri'
                    class='p-button-outlined mb-2' @click='clearFilter' />
            <ButtonRefresh @click='refreshList' :loading='loading' />
            <Button v-if='!hideNew' type='button' icon='pi pi-plus' label='Nuovo'
                    class='p-button-info mb-2 ml-2' @click='newRecord'
            />
            <slot name='after-left'></slot>
        </div>
        <div v-if='!hideFilter'>
            <span class='p-input-icon-left mb-2'>
                <i class='pi pi-search' />
                <InputText v-model='value' placeholder='Keyword Search' style='width: 100%' />
            </span>
        </div>
    </div>
</template>

<script>
import ButtonRefresh from './ButtonRefresh';

export default {
    components: { ButtonRefresh },
    emits: ['clear', 'refresh', 'add', 'update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    props: {
        modelValue: {
            type: String,
            default() {
                return null;
            },
        },
        hideNew: {
            type: Boolean,
            default() {
                return false;
            },
        },
        hideFilter: {
            type: Boolean,
            default() {
                return false;
            },
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    methods: {
        clearFilter() {
            this.$emit('clear');
        },
        refreshList() {
            this.$emit('refresh');
        },
        newRecord() {
            this.$emit('add');
        },
    },
};
</script>
