<template>
    <button class="p-button p-component p-button-help mb-2 ml-2" type="button" :disable="loading" @click='refreshList'>
        <span class="pi pi-refresh p-button-icon p-button-icon-left" :class='{"pi-spin": loading}'></span>
        <span class="p-button-label">Aggiorna</span>
        <span class="p-ink" style="height: 109px; width: 109px; top: -38.2px; left: -2.53751px;"></span>
    </button>
</template>

<script>

export default {
    emits: ['refresh'],
    methods: {
        refreshList(){
            this.$emit('refresh');
        },
    },
    props: {
        loading: {
            type: Boolean,
            default(){
                return false;
            }
        }
    }
}
</script>
