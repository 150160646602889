<template>
    <div class='grid'>
        <Dialog position='top' :header="'Cerca *' + textSearch + '* nei capitoli in ' + LanguageLabel(langSearch) " v-model:visible="showSearched" :style="{width: '50vw'}">
            <DataTable :value='searchedChapters'
                       :paginator='true'
                       class='p-datatable-gridlines'
                       :rows='20'
                       dataKey='id'
                       :rowHover='true'
                       filterDisplay='menu'
                       :loading='loading'
                       responsiveLayout='scroll'
                       v-if='!editing'
            >
                <template #empty>
                    Non ci sono elementi da mostrare.
                </template>
                <template #loading>
                    Loading ... Please wait.
                </template>
                <Column field='aid' header='ArticleID' :sortable='true' style='min-width:12rem'>
                    <template #body='slotProps'>
                        <a
                            :href='getArticleFrontendLink(slotProps.data.aid)'
                            target='_blank'
                            :title='getArticleTitle(slotProps.data.aid)'>{{ slotProps.data.aid }}</a>
                    </template>
                </Column>
                <Column field='edited' header='Fatto' style='min-width:1rem; text-align: center'>
                    <template #body='slotProps'>
                        <Badge value=' ' :severity='slotProps.data.edited ? "success" : "danger"'></Badge>
                    </template>
                </Column>
                <Column field='number' header='Cap' :sortable='true' style='min-width:2rem; text-align: center'/>
                <Column field='title' header='Titolo capitolo' :sortable='true' style='min-width:12rem'/>
                <Column field='id' header='Azioni'>
                    <template #body='slotProps'>
                        <Button icon='pi pi-pencil' class='mr-2 mb-2 p-button-info' title='Modifica'
                                @click='startEdit(slotProps.data)'></Button>
                    </template>
                </Column>
            </DataTable>
            <Card v-if='editing'>
                <template #content>
                    <h4>Articolo:: {{getArticleTitle(editing.aid)}}</h4>
                    <Chapter v-model='editing' @undo='stopEdit'  @save='saveOne' :hideNew='true'/>
                </template>
            </Card>
            <template #footer>
                <Button label="Chiudi" icon="pi pi-times" @click="showSearched = false" class="p-button-text"/>
            </template>
        </Dialog>
        <div class='col-12'>
            <div class='card'>
                <div class='card-header'>
                    <h4>Elenco articoli</h4>
                </div>
                <DataTable :value='rows'
                           :paginator='true'
                           class='p-datatable-gridlines'
                           :rows='20'
                           dataKey='id'
                           :rowHover='true'
                           v-model:filters='filters'
                           filterDisplay='menu'
                           :loading='loading'
                           responsiveLayout='scroll'
                           :globalFilterFields="['slug']"
                           >
                    <template #header>
                        <ListHeader
                            v-model="filters['global'].value"
                            @clear='clearFilter()'
                            @add='$router.push({ path: gotoNew() })'
                            @refresh='refreshList()'
                            :loading='loading'
                        />
                        <div class='grid p-fluid formgrid'>
                            <div class='field col-5 md:col-4'>
                                    <span  class='p-input-icon-left mb-2'>
                                        <i class='pi pi-search' />
                                        <InputText v-model="textSearch" placeholder='Cerca nei capitoli'
                                                   style='width: 100%' />
                                    </span>
                            </div>
                            <div class='field col-4 md:col-2'>
                                <div class='p-inputgroup'>
                                    <Dropdown v-model='langSearch'
                                              id='language_search'
                                              dataKey='id'
                                              :options='dd_languages'
                                              optionLabel='label'
                                              option-value='id'
                                              :filter='true'
                                              filterPlaceholder='Lingua' />
                                </div>
                            </div>
                            <div class='field col-3 md:col-2'>
                                <Button type='button' icon='pi pi-search' label='Cerca'
                                        class='p-button-secondary mb-2 ml-2' @click='inChapterSearch' />
                            </div>
                        </div>
                    </template>
                    <template #empty>
                        Non ci sono elementi da mostrare.
                    </template>
                    <template #loading>
                        Loading ... Please wait.
                    </template>


                    <Column field='slug' header='Slug' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.slug }}
                        </template>
                        <template #filter='{filterModel}'>
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by Slug"/>
                        </template>
                    </Column>

                    <Column field='s_cats' header='Categorie' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.s_cats }}
                        </template>
                    </Column>

                    <Column field='s_aut' header='Autori' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ slotProps.data.s_aut }}
                        </template>
                    </Column>

                    <Column field='last_update' header='Ultima modifica' :sortable='true' style='min-width:12rem'>
                        <template #body='slotProps'>
                            {{ unix2date(slotProps.data.last_update) }}
                        </template>
                    </Column>

                    <Column field='id'>
                        <template #header>
                            Azioni <font-awesome-icon
                            class='pointer ml-2'
                            title='Apri in nuovo Tab'
                            :style='{color: colors.bg.HELP}'
                            :icon="['fas', 'external-link-alt']"
                            size='1x'
                            v-if='linkInNewPage === false'
                            @click='linkInNewPage = true'
                        /><font-awesome-icon
                            class='text-color pointer ml-2'
                            title='Apri nello stesso Tab'
                            :style='{color: colors.bg.INFO}'
                            :icon="['fas', 'file-import']"
                            size='1x'
                            v-else
                            @click='linkInNewPage = false'
                        />
                        </template>
                        <template #body='slotProps'>
                            <router-link :to='gotoId(slotProps.data.id)' :target='linkInNewPage ? "_blank" : "_self"'>
                                <Button icon='pi pi-pencil'
                                        class='mr-2 mb-2'
                                        :class='{"p-button-info": !linkInNewPage, "p-button-help": linkInNewPage}'
                                        title='Modifica'></Button>
                            </router-link>
                        </template>
                    </Column>
                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Cont/Articles');
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import ListHeader from '../../../components/4books/ListHeader';
import DDLanguages from '../../../mixins/dd_languages';
import Chapter from './chapters/Chapter';

export default {
    components: {
        ListHeader, Chapter
    },
    data() {
        return {
            textSearch: "",
            langSearch: "",
            showSearched: false,
            editing: null
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading', 'article', 'searchedChapters']),
    },
    created() {
        this.initFilters();
    },
    mounted() {
        this.refreshList();
        this.langSearch = this.dd_languages[0].id;
    },
    methods: {
        ...mapActions(['fetchList', 'searchInChapters', 'saveChapter']),
        ...mapMutations(['setSearchedChapter']),
        async saveOne(data){
            if (data === null){
                this.ShowWarning('Strano', 'Pare non ci sia nulla da salvare');
                return null;
            }
            try {
                await this.saveChapter({
                    chapter: data,
                    article_id: data.aid
                });
                this.ShowSuccess("Ottimo", "Il capitolo è stato salvato");
                data.edited = true;
                this.setSearchedChapter(data);
                this.stopEdit();
            } catch(err) {
                this.ShowStandardError(err.response);
            }
        },
        stopEdit(){
            this.editing = null;
        },
        startEdit(v){
            this.editing = v;
        },
        getArticleFrontendLink(aid){
            const art = this.article(aid);
            const slug = (art && art.slug) ? art.slug : "";
            return this.getFrontendArticleUrl(this.langSearch, slug);
        },
        getArticleTitle(aid){
            const art = this.article(aid);
            return (art && art.slug) ? art.slug : "-";
        },
        refreshList() {
            this.fetchList().then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initFilters() {
            this.initFiltersByAllFields({});
            this.addFiltersByStartWith('slug');
        },
        clearFilter() {
            this.initFilters();
        },
        async inChapterSearch(){
            try {
                await this.searchInChapters({
                    text: this.textSearch,
                    lang: this.langSearch
                });
                this.showSearched = true;
            } catch(err) {
                this.ShowStandardError(err.response);
            }
        }
    },
    mixins: [
        Notifications,
        Navigations,
        DDLanguages
    ],
};
</script>
